<template>
  <div class="not_found">
    <p>404 | Page Not Found</p>
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>
.not_found {
  background-color: #F5F8FA;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  direction: ltr;
}

.not_found p {
  color: #5e6278;
}
</style>
